@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon/icomoon.eot?fj7xbx');
  src:  url('../fonts/icomoon/icomoon.eot?fj7xbx#iefix') format('embedded-opentype'),
    url('../fonts/icomoon/icomoon.ttf?fj7xbx') format('truetype'),
    url('../fonts/icomoon/icomoon.woff?fj7xbx') format('woff'),
    url('../fonts/icomoon/icomoon.svg?fj7xbx#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-tick1:before {
  content: "\e92d";
}
.icon-inspections2:before {
  content: "\e92e";
}
.icon-view02:before {
  content: "\e92f";
}
.icon-history:before {
  content: "\e917";
}
.icon-ledger:before {
  content: "\e91a";
}
.icon-MRI_PT_logo .path1:before {
  content: "\e91b";
  color: rgb(4, 77, 102);
}
.icon-MRI_PT_logo .path2:before {
  content: "\e91c";
  margin-left: -4.2392578125em;
  color: rgb(186, 210, 54);
}
.icon-MRI_PT_logo .path3:before {
  content: "\e91d";
  margin-left: -4.2392578125em;
  color: rgb(4, 77, 102);
}
.icon-MRI_PT_logo .path4:before {
  content: "\e91e";
  margin-left: -4.2392578125em;
  color: rgb(186, 210, 54);
}
.icon-MRI_PT_logo .path5:before {
  content: "\e91f";
  margin-left: -4.2392578125em;
  color: rgb(4, 77, 102);
}
.icon-MRI_PT_logo .path6:before {
  content: "\e920";
  margin-left: -4.2392578125em;
  color: rgb(4, 77, 102);
}
.icon-MRI_PT_logo .path7:before {
  content: "\e921";
  margin-left: -4.2392578125em;
  color: rgb(4, 77, 102);
}
.icon-MRI_PT_logo .path8:before {
  content: "\e922";
  margin-left: -4.2392578125em;
  color: rgb(4, 77, 102);
}
.icon-MRI_PT_logo .path9:before {
  content: "\e924";
  margin-left: -4.2392578125em;
  color: rgb(4, 77, 102);
}
.icon-MRI_PT_logo .path10:before {
  content: "\e925";
  margin-left: -4.2392578125em;
  color: rgb(4, 77, 102);
}
.icon-MRI_PT_logo .path11:before {
  content: "\e926";
  margin-left: -4.2392578125em;
  color: rgb(4, 77, 102);
}
.icon-MRI_PT_logo .path12:before {
  content: "\e927";
  margin-left: -4.2392578125em;
  color: rgb(4, 77, 102);
}
.icon-MRI_PT_logo .path13:before {
  content: "\e928";
  margin-left: -4.2392578125em;
  color: rgb(4, 77, 102);
}
.icon-MRI_PT_logo .path14:before {
  content: "\e929";
  margin-left: -4.2392578125em;
  color: rgb(4, 77, 102);
}
.icon-MRI_PT_logo .path15:before {
  content: "\e92a";
  margin-left: -4.2392578125em;
  color: rgb(4, 77, 102);
}
.icon-MRI_PT_logo .path16:before {
  content: "\e92b";
  margin-left: -4.2392578125em;
  color: rgb(4, 77, 102);
}
.icon-Brand-blue:before {
  content: "\e92c";
  color: #044d66;
}
.icon-logo-mri-pt .path1:before {
  content: "\e903";
  color: rgb(2, 77, 102);
}
.icon-logo-mri-pt .path2:before {
  content: "\e904";
  margin-left: -3.517578125em;
  color: rgb(186, 210, 54);
}
.icon-logo-mri-pt .path3:before {
  content: "\e905";
  margin-left: -3.517578125em;
  color: rgb(2, 77, 102);
}
.icon-logo-mri-pt .path4:before {
  content: "\e906";
  margin-left: -3.517578125em;
  color: rgb(186, 210, 54);
}
.icon-logo-mri-pt .path5:before {
  content: "\e907";
  margin-left: -3.517578125em;
  color: rgb(2, 77, 102);
}
.icon-logo-mri-pt .path6:before {
  content: "\e908";
  margin-left: -3.517578125em;
  color: rgb(2, 77, 102);
}
.icon-logo-mri-pt .path7:before {
  content: "\e909";
  margin-left: -3.517578125em;
  color: rgb(2, 77, 102);
}
.icon-logo-mri-pt .path8:before {
  content: "\e90a";
  margin-left: -3.517578125em;
  color: rgb(2, 77, 102);
}
.icon-logo-mri-pt .path9:before {
  content: "\e90b";
  margin-left: -3.517578125em;
  color: rgb(2, 77, 102);
}
.icon-logo-mri-pt .path10:before {
  content: "\e90c";
  margin-left: -3.517578125em;
  color: rgb(2, 77, 102);
}
.icon-logo-mri-pt .path11:before {
  content: "\e90d";
  margin-left: -3.517578125em;
  color: rgb(2, 77, 102);
}
.icon-logo-mri-pt .path12:before {
  content: "\e90e";
  margin-left: -3.517578125em;
  color: rgb(2, 77, 102);
}
.icon-logo-mri-pt .path13:before {
  content: "\e90f";
  margin-left: -3.517578125em;
  color: rgb(2, 77, 102);
}
.icon-logo-mri-pt .path14:before {
  content: "\e910";
  margin-left: -3.517578125em;
  color: rgb(2, 77, 102);
}
.icon-logo-mri-pt .path15:before {
  content: "\e911";
  margin-left: -3.517578125em;
  color: rgb(2, 77, 102);
}
.icon-logo-mri-pt .path16:before {
  content: "\e912";
  margin-left: -3.517578125em;
  color: rgb(2, 77, 102);
}
.icon-archived-property:before {
  content: "\e901";
}
.icon-face-error:before {
  content: "\e63d";
}
.icon-face-notification:before {
  content: "\e63e";
}
.icon-face-success:before {
  content: "\e63f";
}
.icon-face-warning:before {
  content: "\e640";
}
.icon-mortar-board:before {
  content: "\e63b";
}
.icon-logo-propertytree-tree-beta:before {
  content: "\e63a";
}
.icon-logo-propertytree-beta:before {
  content: "\e638";
}
.icon-logo-propertytree-poweredby-beta:before {
  content: "\e639";
}
.icon-logo-propertytree-tree:before {
  content: "\e66f";
}
.icon-logo-propertytree:before {
  content: "\e637";
}
.icon-smile-wink:before {
  content: "\e634";
}
.icon-google:before {
  content: "\e62e";
}
.icon-microsoft:before {
  content: "\e62d";
}
.icon-bedroom:before {
  content: "\e629";
}
.icon-car:before {
  content: "\e62a";
}
.icon-shower:before {
  content: "\e62b";
}
.icon-custom-arrow-down:before {
  content: "\e609";
}
.icon-custom-arrow-up:before {
  content: "\e608";
}
.icon-rent-history:before {
  content: "\e902";
}
.icon-spanner-outline:before {
  content: "\e900";
}
.icon-info-large:before {
  content: "\e636";
}
.icon-news:before {
  content: "\e630";
}
.icon-document-text:before {
  content: "\e631";
}
.icon-clipboard:before {
  content: "\e632";
}
.icon-power-outline:before {
  content: "\e633";
}
.icon-delete:before {
  content: "\e62f";
}
.icon-tick:before {
  content: "\e625";
}
.icon-chevron-right-outline:before {
  content: "\e61a";
}
.icon-chevron-left-outline:before {
  content: "\e61b";
}
.icon-home-outline:before {
  content: "\e60a";
}
.icon-trash:before {
  content: "\e60b";
}
.icon-location-outline:before {
  content: "\e60c";
}
.icon-image-outline:before {
  content: "\e60d";
}
.icon-heart-outline:before {
  content: "\e60f";
}
.icon-flash-outline:before {
  content: "\e610";
}
.icon-cancel-outline:before {
  content: "\e611";
}
.icon-warning-outline:before {
  content: "\e626";
}
.icon-info-outline:before {
  content: "\e612";
}
.icon-attachment-outline:before {
  content: "\e613";
}
.icon-chart-line-outline:before {
  content: "\e614";
}
.icon-eye-outline:before {
  content: "\e615";
}
.icon-cog-outline:before {
  content: "\e616";
}
.icon-upload-outline:before {
  content: "\e624";
}
.icon-info-large-outline:before {
  content: "\e617";
}
.icon-download-outline:before {
  content: "\e61c";
}
.icon-zoom-outline:before {
  content: "\e618";
}
.icon-pencil:before {
  content: "\e61d";
}
.icon-folder:before {
  content: "\e61e";
}
.icon-folder-delete:before {
  content: "\e61f";
}
.icon-folder-add:before {
  content: "\e620";
}
.icon-edit:before {
  content: "\e621";
}
.icon-device-phone:before {
  content: "\e619";
}
.icon-printer:before {
  content: "\e623";
}
.icon-lightbulb:before {
  content: "\e627";
}
.icon-messages:before {
  content: "\e628";
}
.icon-time:before {
  content: "\e641";
}
.icon-uniE622:before {
  content: "\e622";
}
.icon-googleplus:before {
  content: "\e600";
}
.icon-facebook:before {
  content: "\e601";
}
.icon-twitter:before {
  content: "\e602";
}
.icon-vimeo:before {
  content: "\e603";
}
.icon-blogger:before {
  content: "\e604";
}
.icon-skype:before {
  content: "\e605";
}
.icon-linkedin:before {
  content: "\e606";
}
.icon-pinterest:before {
  content: "\e607";
}
.icon-file:before {
  content: "\e62c";
}
.icon-support:before {
  content: "\e635";
}
.icon-attached2:before {
  content: "\e94a";
}
.icon-summary-property:before {
  content: "\e943";
}
.icon-summary-owner:before {
  content: "\e940";
}
.icon-help-circle:before {
  content: "\e9b9";
}
.icon-moveout1:before {
  content: "\e939";
}
.icon-file-text:before {
  content: "\e9a2";
}
.icon-car1:before {
  content: "\e915";
}
.icon-times:before {
  content: "\e914";
}
.icon-document2:before {
  content: "\e918";
}
.icon-trust:before {
  content: "\e919";
}
.icon-maintenance:before {
  content: "\e923";
}
.icon-bathroom:before {
  content: "\e931";
}
.icon-search:before {
  content: "\e930";
}
.icon-mail:before {
  content: "\e913";
}
.icon-user:before {
  content: "\e916";
}
.icon-bed:before {
  content: "\f236";
}
